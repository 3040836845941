import userImage from "./sidenav/images";
import tokens from "./trade/tokens";
import tokenImages from "./trade/images";
import navEntities from "./sidenav/navEntities";
import generalImages from "./general/images";

const nav = {
  userImage
};

export {
  nav, navEntities,
  tokenImages, tokens,
  generalImages
};