import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import s from "./Settings.module.scss";

export default function Settings():JSX.Element {
  const theme = localStorage.getItem("theme");
  if (theme === null) {
    localStorage.setItem("theme", "true");
  }

  const [ themeState, setThemeState ] = useState<string | null>(theme);
  const [ policy, setPolicy ] = useState<boolean>(false);

  useEffect(() => {
    setThemeState(theme);
    if (themeState === "true") {
      document.documentElement.className = "dark-mode";
    } else if (themeState === "false") {
      document.documentElement.className = "";
    }
  }, [ themeState ]);

  const switchTheme = () => {
    if (themeState === "true") {
      document.documentElement.className = "dark-mode";
      setThemeState("false");
      localStorage.setItem("theme", "false");
    } else if (themeState === "false") {
      document.documentElement.className = "";
      setThemeState("true");
      localStorage.setItem("theme", "true");
    }
  };

  useEffect(() => {
    setPolicy(true);
  }, [ ]);

  return (
    <main className={`${s.settings_main} p-20 flex flex-column`}>
      <div className={`${s.settings_container}`}>
        <h2 className="f-30 b-600">Settings</h2>

        <div className={`${s.settings_body}`}>
          <ul className="">
            <li className="flex flex-row justify-space-between align-center">
              <h3 className="b-500">Langauge</h3>
              <select className="border-silver bg-white grey-text p-10 br-5" name="" id="">
                <option value="english" className="">ENG</option>
                <option value="french" className="">FR</option>
                <option value="german" className="">GER</option>
              </select>
            </li>

            <li className="flex flex-row justify-space-between align-center">
              <h3 className="b-500">Theme</h3>
              <Switch onChange={switchTheme} checked={JSON.parse(themeState as string)} className={s.switch} />
            </li>

            <li className="flex flex-row justify-space-between align-center">
              <h3 className="b-500">Policy</h3>
              {/* eslint-disable-next-line */}
              <button onClick={() => setPolicy(!policy)} className="btn"><InfoOutlinedIcon /></button>
            </li>
          </ul>
        </div>
      </div>

      {
        policy
        && (
          <section className={`${s.cookie_policy} bg-blue flex flex-row align-center justify-space-between g-20 p-25`}>
            <div className={`${s.left} white-text flex flex-row g-20 align-center`}>
              <div className={`${s.sub_left}`}>
                <InfoOutlinedIcon />
              </div>
              <div className={`${s.sub_right}`}>
                <h3 className="">Cookies &amp; Privacy</h3>
                <p className="">We use cookies to enhance your experience, analyze our traffic, and for security and marketing. By visiting our website you agree to our use of cookies.</p>
              </div>
            </div>
            <div className={`${s.right} g-20`}>
              <button type="button" className="btn bg-purple white-text">
                More Information
              </button>
              <button onClick={() => setPolicy(false)} type="button" className="btn white-text bg-purple max-height">Understood</button>
              {/* eslint-disable-next-line */}
              {/* <button onClick={() => setPolicy(false)} type="button" className="btn bg-transparent"><CloseIcon /></button> */}
            </div>
          </section>
        )
      }
    </main>
  );
}