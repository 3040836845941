import React, { useState } from "react";
import { Dialog, Select, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpIcon from "@mui/icons-material/Help";
import WarningIcon from "@mui/icons-material/Warning";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./DepositModal.module.scss";
import { tokens } from "../../constants";

type TProps = {
  modalOpen: boolean,
  setModalOpen :React.Dispatch<React.SetStateAction<boolean>>
}

export default function DepositModal({ modalOpen, setModalOpen }:TProps):JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [ dropdownState, setDropdownState ] = useState<boolean>(false);
  const [ selectedItem, setSelectedItem ] = useState({ image: "", abbreviation: "" });
  const [ errorFlag, setErrorFlag ] = useState<boolean>(false);

  const handleModalClose = ():void => {
    setModalOpen(false);
  };

  const handleDropdown = () => {
    setDropdownState(!dropdownState);
  };

  const handleSelectItem = (image, abbreviation) => {
    setSelectedItem({
      image, abbreviation
    });
    setDropdownState(!dropdownState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorFlag(true);
  };

  return (
    <Dialog
      open={modalOpen}
        // style={{maxWidth: "1500px", backgroundColor: "red"}}
      className={s.dialog_container}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      {/*= ========================= */}
      {/* Button For Closing Modal */}
      {/*= ========================= */}
      <button type="button" onClick={handleModalClose} className={s.close_button}>
        <CloseIcon />
      </button>

      <form
        className={`p-20 ${s.new_institution_modal}`}
        onSubmit={handleSubmit}
        style={{}}
      >
        <h3 className="m-t-0 m-b-25">Deposit</h3>

        <p className="m-b-5">Asset</p>

        <div className={`${s.select} border-silver br-5`}>
          {/* eslint-disable-next-line */}
          <button onClick={handleDropdown} type="button" className={`${s.down_arrow}`}><KeyboardArrowDownIcon /></button>
          <div className={`${s.options_container}`} style={{ position: dropdownState ? "fixed" : "unset" }}>
            {
              dropdownState === true
                ? tokens.map((token, index) => (
                  // eslint-disable-next-line
                  <div onClick={() => handleSelectItem(token.image, token.abbreviation)} key={index} className={`${s.option} flex flex-row g-10 p-10 m-b-`}>
                    <img src={token.image} alt="" className="" />
                    <p className="no-m-p">{token.abbreviation}</p>
                  </div>
                ))
                : (
                  <div className={`${s.rt} flex flex-row g-10 p-10 m-b-`}>
                    <img src={selectedItem.image} alt="" className="" />
                    <p className="no-m-p">{selectedItem.abbreviation}</p>
                  </div>
                )
            }
          </div>
        </div>

        <section className="m-t-25">
          <p className="m-b-5">Amount</p>
          <div className={`${s.amount} flex flex-row justify-space-between border-silver p-10 br-5`}>
            <span className="grey-text">0.0 BTC</span>
            <span className="blue-text">MAX</span>
          </div>
        </section>

        <section className="m-t-20">
          <div className={`${s.amount} flex flex-row justify-space-between br-5`}>
            <span className="grey-text">Available</span>
            <span className="blue">0.00 USDT</span>
          </div>
        </section>

        <section className="m-t-25">
          <p className={`${s.info} m-b-5 flex flex-row align-center g-5 f-15`}>
            Expiration time
            <Tooltip
              title="Cancelled transactions are finalized only expiration time, before releasing locked
                funds. You can define the expiration time of your deposits/withdrawals on the exchange.
                ** Please use appropriate gas fees. Transactions need to be confirmed before expiration
                time, otherwise, will be deemed invalid."
              arrow
              placement="top"
            >
              <HelpIcon />
            </Tooltip>
          </p>
          <div className={`${s.amount} flex flex-row justify-space-between m-t-5 p-5 br-5`}>
            <label htmlFor="5m" className="grey-text flex flex-row g-5 align-center">
              <input id="5m" type="radio" name="expiry" className="" />
              <span className="">5 m</span>
            </label>
            <label htmlFor="10m" className="grey-text flex flex-row g-5 align-center">
              <input id="10m" type="radio" name="expiry" className="" />
              <span className="">10 m</span>
            </label>
            <label htmlFor="30m" className="grey-text flex flex-row g-5 align-center">
              <input id="30m" type="radio" name="expiry" className="" />
              <span className="">30 m</span>
            </label>
            <label htmlFor="1h" className="grey-text flex flex-row g-5 align-center">
              <input id="1h" type="radio" name="expiry" className="" />
              <span className="">1 h</span>
            </label>
          </div>
        </section>

        <section className="m-t-15">
          <span className="blue-text f-15">See deposit history</span>
        </section>

        {
          errorFlag
          && (
            <section className={`${s.error_section} flex flex-row g-10 align-center bg-light-red p-20 m-t-20`}>
              <div className={`${s.left}`}><WarningIcon /></div>
              <div className={`${s.right} flex flex-column g-5`}>
                <span className="red-text">Your wallet network is incorrect.</span>
                <span className="red-text">Please make sure it&apos;s set to ‘Ethereum Mainnet&apos;.</span>
              </div>
            </section>
          )
        }
        <section className="flex flex-row g-10 justify-flex-end m-t-25">
          <button type="button" onClick={handleModalClose} className="btn bg-very-light-silver border-silver">Cancel</button>
          <button type="submit" className="btn bg-green white-text">Confirm deposit</button>
        </section>

        {/* <span className="flex p-l-20 p-r-20 br-10 g-20 justify-space-between  bg-light-silver">
          <span className={`flex g-10 ${s.left}`}>
            <p className="b-600">Date Created: </p>
            <p className="">June 12, 2022 09:03</p>
          </span>

          <span className={`flex g-10 ${s.right}`}>
            <p className="b-600">Number Id: </p>
            <p className="">#T20102</p>
          </span>
        </span>

        <span className="flex p-10 align-center m-t-20 p-r-20 br-10 g-20 justify-space-between  bg-light-silver">
          <span className={`flex g-10 align-center ${s.left}`}>
            <p className="b-600 no-m-p">First name: </p>
            <input type="text" className="p-5 f-15 p-l-20 br-5" />
          </span>
          <span className={`flex g-10 align-center ${s.left}`}>
            <p className="b-600 no-m-p">Last name: </p>
            <input type="text" className="p-5 f-15 p-l-20 br-5" />
          </span>
        </span>

        <div className={`flex flex-row g-30 justify-space-between m-t-20 ${s.input_container}`}>
          <div className={`fullwidth flex flex-column g-10 ${s.input_left}`}>
            <label className="" htmlFor="description">
              <p className="">Match:</p>
              <p className="grey-text">Are you sure you want to flag the search as a match?</p>
              <textarea
                name=""
                rows={10}
                id="description"
                placeholder="Enter a note..."
                className="fullwidth br-5 bg-light-silver p-10"
              />
            </label>
          </div>
        </div>

        <div className={`flex  justify-end m-t-40 ${s.button_container}`}>
          <button
            type="button"
            onClick={(e:any) => handleSubmit(e)}
            className="btn bg-blue white-text"
          >
            Match
          </button>
        </div> */}
      </form>
    </Dialog>
  );
}