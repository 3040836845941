import { Paper } from "@mui/material";
import { useState } from "react";
import AnyChart from "anychart-react";
import CloseIcon from "@mui/icons-material/Close";
import { tokenImages } from "../../../constants";
import s from "./TradeMain.module.scss";

type TProps = {
  chartInView: boolean,
  setChartInView: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TradeMain({ chartInView, setChartInView }: TProps):JSX.Element {
  const ordersArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

  const [ chartDetails, setChartDetails ] = useState([
    [ Date.UTC(2007, 6, 1), 6593.99, 6598.89, 6585, 6587.81 ],
    [ Date.UTC(2007, 6, 2), 6588.86, 6600, 6580, 6593.4 ],
    [ Date.UTC(2007, 6, 3), 6598.86, 6598.88, 6570, 6587.16 ],
    [ Date.UTC(2007, 6, 4), 6619, 6625.97, 6595.27, 6598.86 ],
    [ Date.UTC(2007, 6, 5), 6625.95, 6626, 6611.66, 6617.58 ],
    [ Date.UTC(2007, 6, 6), 6612, 6624.12, 6608.43, 6622.95 ],
    [ Date.UTC(2007, 6, 7), 6623.91, 6623.91, 6615, 6615.67 ],
    [ Date.UTC(2007, 6, 8), 6618.69, 6618.74, 6610, 6610.4 ],
    [ Date.UTC(2007, 6, 9), 6611, 6622.78, 6610.4, 6614.9 ],
    [ Date.UTC(2007, 6, 10), 6614.9, 6626.2, 6613.33, 6623.45 ],
    [ Date.UTC(2007, 6, 11), 6623.48, 6627, 6618.38, 6620.35 ],
    [ Date.UTC(2007, 6, 12), 6619.43, 6620.35, 6610.05, 6615.53 ],
    [ Date.UTC(2007, 6, 13), 6615.53, 6617.93, 6610, 6615.19 ],
    [ Date.UTC(2007, 6, 14), 6615.19, 6621.6, 6608.2, 6620 ],
    [ Date.UTC(2007, 6, 15), 6619.54, 6625.17, 6614.15, 6620 ],
    [ Date.UTC(2007, 6, 16), 6620.33, 6634.15, 6617.24, 6624.61 ],
    [ Date.UTC(2007, 6, 17), 6629.81, 6650.5, 6623.04, 6633.33 ],
    [ Date.UTC(2007, 6, 18), 6632.01, 6643.59, 6620, 6630.11 ],
    [ Date.UTC(2007, 6, 19), 6630.71, 6648.95, 6623.34, 6635.65 ],
    [ Date.UTC(2007, 6, 21), 6635.65, 6651, 6629.67, 6638.24 ],
    [ Date.UTC(2007, 6, 22), 6638.24, 6640, 6620, 6624.47 ],
    [ Date.UTC(2007, 6, 23), 6624.53, 6636.03, 6621.68, 6624.31 ],
    [ Date.UTC(2007, 6, 24), 6624.61, 6632.2, 6617, 6626.02 ],
    [ Date.UTC(2007, 6, 25), 6627, 6627.62, 6584.22, 6603.02 ],
    [ Date.UTC(2007, 6, 26), 6605, 6608.03, 6598.95, 6604.01 ],
    [ Date.UTC(2007, 6, 27), 6604.5, 6614.4, 6602.26, 6608.02 ],
    [ Date.UTC(2007, 6, 28), 6608.02, 6610.68, 6601.99, 6608.91 ],
    [ Date.UTC(2007, 6, 29), 6608.91, 6618.99, 6608.01, 6612 ],
    [ Date.UTC(2007, 6, 30), 6612, 6615.13, 6605.09, 6612 ],
    [ Date.UTC(2007, 6, 31), 6612, 6625.13, 6605.09, 6602 ],
    [ Date.UTC(2007, 7, 1), 6593.99, 6598.89, 6585, 6587.81 ],
    [ Date.UTC(2007, 7, 2), 6588.86, 6600, 6580, 6593.4 ],
    [ Date.UTC(2007, 7, 3), 6598.86, 6598.88, 6570, 6587.16 ],
    [ Date.UTC(2007, 7, 4), 6619, 6625.97, 6595.27, 6598.86 ],
    [ Date.UTC(2007, 7, 5), 6625.95, 6626, 6611.66, 6617.58 ],
    [ Date.UTC(2007, 7, 6), 6612, 6624.12, 6608.43, 6622.95 ],
    [ Date.UTC(2007, 7, 7), 6623.91, 6623.91, 6615, 6615.67 ],
    [ Date.UTC(2007, 7, 8), 6618.69, 6618.74, 6610, 6610.4 ],
    [ Date.UTC(2007, 7, 9), 6611, 6622.78, 6610.4, 6614.9 ],
    [ Date.UTC(2007, 7, 10), 6614.9, 6626.2, 6613.33, 6623.45 ],
    [ Date.UTC(2007, 7, 11), 6623.48, 6627, 6618.38, 6620.35 ],
    [ Date.UTC(2007, 7, 12), 6619.43, 6620.35, 6610.05, 6615.53 ],
    [ Date.UTC(2007, 7, 13), 6615.53, 6617.93, 6610, 6615.19 ],
    [ Date.UTC(2007, 7, 14), 6615.19, 6621.6, 6608.2, 6620 ],
    [ Date.UTC(2007, 7, 15), 6619.54, 6625.17, 6614.15, 6620 ],
    [ Date.UTC(2007, 7, 16), 6620.33, 6634.15, 6617.24, 6624.61 ],
    [ Date.UTC(2007, 7, 17), 6629.81, 6650.5, 6623.04, 6633.33 ],
    [ Date.UTC(2007, 7, 18), 6632.01, 6643.59, 6620, 6630.11 ],
    [ Date.UTC(2007, 7, 19), 6630.71, 6648.95, 6623.34, 6635.65 ],
    [ Date.UTC(2007, 7, 21), 6635.65, 6651, 6629.67, 6638.24 ],
    [ Date.UTC(2007, 7, 22), 6638.24, 6640, 6620, 6624.47 ],
    [ Date.UTC(2007, 7, 23), 6624.53, 6636.03, 6621.68, 6624.31 ],
    [ Date.UTC(2007, 7, 24), 6624.61, 6632.2, 6617, 6626.02 ],
    [ Date.UTC(2007, 7, 25), 6627, 6627.62, 6584.22, 6603.02 ],
    [ Date.UTC(2007, 7, 26), 6605, 6608.03, 6598.95, 6604.01 ],
    [ Date.UTC(2007, 7, 27), 6604.5, 6614.4, 6602.26, 6608.02 ],
    [ Date.UTC(2007, 7, 28), 6608.02, 6610.68, 6601.99, 6608.91 ],
    [ Date.UTC(2007, 7, 29), 6608.91, 6618.99, 6608.01, 6612 ],
    [ Date.UTC(2007, 7, 30), 6612, 6615.13, 6605.09, 6612 ],
    [ Date.UTC(2007, 8, 1), 6593.99, 6598.89, 6585, 6587.81 ],
    [ Date.UTC(2007, 8, 2), 6588.86, 6600, 6580, 6593.4 ],
    [ Date.UTC(2007, 8, 3), 6598.86, 6598.88, 6570, 6587.16 ],
    [ Date.UTC(2007, 8, 4), 6619, 6625.97, 6595.27, 6598.86 ],
    [ Date.UTC(2007, 8, 5), 6625.95, 6626, 6611.66, 6617.58 ],
    [ Date.UTC(2007, 8, 6), 6612, 6624.12, 6608.43, 6622.95 ],
    [ Date.UTC(2007, 8, 7), 6623.91, 6623.91, 6615, 6615.67 ],
    [ Date.UTC(2007, 8, 8), 6618.69, 6618.74, 6610, 6610.4 ],
    [ Date.UTC(2007, 8, 9), 6611, 6622.78, 6610.4, 6614.9 ],
    [ Date.UTC(2007, 8, 10), 6614.9, 6626.2, 6613.33, 6623.45 ],
    [ Date.UTC(2007, 8, 11), 6623.48, 6627, 6618.38, 6620.35 ],
    [ Date.UTC(2007, 8, 12), 6619.43, 6620.35, 6610.05, 6615.53 ],
    [ Date.UTC(2007, 8, 13), 6615.53, 6617.93, 6610, 6615.19 ],
    [ Date.UTC(2007, 8, 14), 6615.19, 6621.6, 6608.2, 6620 ],
    [ Date.UTC(2007, 8, 15), 6619.54, 6625.17, 6614.15, 6620 ],
    [ Date.UTC(2007, 8, 16), 6620.33, 6634.15, 6617.24, 6624.61 ],
    [ Date.UTC(2007, 8, 17), 6629.81, 6650.5, 6623.04, 6633.33 ],
    [ Date.UTC(2007, 8, 18), 6632.01, 6643.59, 6620, 6630.11 ],
    [ Date.UTC(2007, 8, 19), 6630.71, 6648.95, 6623.34, 6635.65 ],
    [ Date.UTC(2007, 8, 21), 6635.65, 6651, 6629.67, 6638.24 ],
    [ Date.UTC(2007, 8, 22), 6638.24, 6640, 6620, 6624.47 ],
    [ Date.UTC(2007, 8, 23), 6624.53, 6636.03, 6621.68, 6624.31 ],
    [ Date.UTC(2007, 8, 24), 6624.61, 6632.2, 6617, 6626.02 ],
    [ Date.UTC(2007, 8, 25), 6627, 6627.62, 6584.22, 6603.02 ],
    [ Date.UTC(2007, 8, 26), 6605, 6608.03, 6598.95, 6604.01 ],
    [ Date.UTC(2007, 8, 27), 6604.5, 6614.4, 6602.26, 6608.02 ],
    [ Date.UTC(2007, 8, 28), 6608.02, 6610.68, 6601.99, 6608.91 ],
    [ Date.UTC(2007, 8, 29), 6608.91, 6618.99, 6608.01, 6612 ],
    [ Date.UTC(2007, 8, 30), 6612, 6615.13, 6605.09, 6612 ]

  // [Date.UTC(2007, 7, 23), 23.55, 23.88, 23.38, 23.62],
  // [Date.UTC(2007, 7, 24), 22.65, 23.7, 22.65, 23.36],
  // [Date.UTC(2007, 7, 25), 22.75, 23.7, 22.69, 23.44],
  // [Date.UTC(2007, 7, 26), 23.2, 23.39, 22.87, 22.92],
  // [Date.UTC(2007, 7, 27), 23.98, 24.49, 23.47, 23.49],
  ]);

  return (
    <div className={`${s.trade_main}`}>
      <section className={`${s.bar}`}>
        <Paper elevation={2} className={`${s.bar_container} fullwidth fullheight`}>
          <div className={`${s.bar_wrapper}`}>
            <ul className="p-10 p-l-30 p-r-30 g-15 flex flex-row justify-space-between align-center fullheight">
              <li className="flex flex-row align-center g-10">
                <img src={tokenImages.btc} alt="bitcoim" className="" />
                <h3 className="no-m-p f-25">BTC/USDT</h3>
              </li>
              <li className="">
                <select name="" id="" className="dusted-purple-text f-18">
                  <option value="p-10" className="">All markets</option>
                  <option value="p-10" className="">Other options</option>
                </select>
              </li>
              <li className={`${s.line}`}>
                <div className="" />
              </li>
              <li className="flex flex-column">
                <h3 className="no-m-p green-text">62,238.00</h3>
                <p className="no-m-p dusted-purple-text">Last market price</p>
              </li>
              <li className={`${s.line}`}>
                <div className="" />
              </li>
              <li className="flex flex-column g-5">
                <p className="no-m-p green-text">+1.75%</p>
                <p className="no-m-p dusted-purple-text">24h Change</p>
              </li>
              <li className="flex flex-column g-5">
                <p className="no-m-p b-600">63,597.80</p>
                <p className="no-m-p f-15 dusted-purple-text">24h High</p>
              </li>
              <li className="flex flex-column g-5">
                <p className="no-m-p b-600">60,322.6</p>
                <p className="no-m-p f-15 dusted-purple-text">24h Low</p>
              </li>
              <li className="flex flex-column g-5">
                <p className="no-m-p b-600">2,548,722,097.16</p>
                <p className="no-m-p f-15 dusted-purple-text">Market Volume</p>
              </li>
            </ul>
          </div>
        </Paper>
      </section>

      <section className={`${s.mobile_bar} `}>
        <Paper elevation={3} className={`${s.mobile_bar_paper} fullwidth fullheight`}>
          <div className={`${s.left} flex flex-row g-10 p-l-10`}>
            <div className={`${s.image_container}`}>
              <img src={tokenImages.btc} alt="" className="" />
            </div>
            <div className={`${s.sub_right}`}>
              <p className="no-m-p">
                <strong>BTC</strong> <span className="dusted-purple-text">/ USDT</span>
              </p>
              <p className="no-m-p dusted-purple-text">Vol 2.8B</p>
            </div>
          </div>
          <div className={`${s.right} p-r-10`}>
            <p className="f-20 no-m-p b-700">49,220.60</p>
            <p className="f-12 no-m-p green-text">+3,73%</p>
          </div>
        </Paper>
      </section>

      <section className={`${s.chart} ${!chartInView && s.hide_chart}`}>
        <Paper elevation={2} className={`${s.chart_paper} p-10 fullwidth fullheight`}>
          <div className={s.chart_container}>
            <AnyChart
              type="candlestick"
              data={chartDetails}
              // title="OpenDax"
            />
          </div>
        </Paper>
      </section>

      <section className={`${s.open_orders}`}>
        <Paper elevation={2} className={`fullwidth fullheight ${s.orders_card}`}>
          <div className={`p-10 p-l-15 p-r-15 ${s.top} fullwidth flex flex-row justify-space-between`}>
            <h3 className="no-m-p b-600">OPEN ORDERS</h3>
            <p className="no-m-p blue-text f-900"><a href="https://hycient.vercel.app/" rel="noopener noreferrer" target="_blank" className="b-600 no-underline">Dev Profile</a></p>
          </div>
          <div className={`${s.table} fullwidth`}>
            <div className={`bg-light-silver ${s.table_heading}`}>
              <ul>
                <li>DATE</li>
                <li>MARKET</li>
                <li>SIDE</li>
                <li>PRICE</li>
                <li>AMOUNT</li>
                <li>VALUE</li>
                <li>FILLED</li>
                <li>STATUS</li>
              </ul>
            </div>
            <div className={`${s.table_body}`}>
              <div className={`${s.table_body_container}`}>
                {
                  ordersArray.map((entity, index) => (
                    <ul key={index}>
                      <li>18-06-2017 12:38:42</li>
                      <li className="dusted-purple-text">ETH/USDT</li>
                      <li className="green-text">Buy</li>
                      <li className="dusted-purple-text">3582.18</li>
                      <li className="dusted-purple-text">4030.40</li>
                      <li className="dusted-purple-text">4030.40</li>
                      <li className="dusted-purple-text">0%</li>
                      <li className="bg-light-green dark-green-text centralize p-5 br-10 m-r-5">Open</li>
                      <li className="red-text b-500 flex flex-row align-center">Cancel <CloseIcon /></li>
                    </ul>
                  ))
                }
              </div>
            </div>
          </div>
        </Paper>
      </section>

    </div>
  );
}