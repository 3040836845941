import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, ButtonGroup, Paper, Tooltip } from "@mui/material";
import s from "./Withdraw.module.scss";

export default function OpenOrders():JSX.Element {
  const ordersArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21 ];

  return (
    <main className={`${s.all_Orders_main}`}>
      <section className={`${s.open_orders}`}>
        <Paper elevation={0} className={`fullwidth fullheight ${s.orders_card}`}>
          {/* <div className={`p-10 p-l-15 ${s.top} fullwidth`}>
            <h3 className="no-m-p b-500">OPEN ORDERS</h3>
          </div> */}
          <div className={`${s.table} fullwidth`}>
            <div className={`bg-light-silver ${s.table_heading}`}>
              <ul>
                <li>DATE</li>
                <li>ID</li>
                <li>AMOUNT</li>
                <li>RID</li>
                <li>ASSET</li>
                <li>TXID</li>
                <li>STATUS</li>
              </ul>
            </div>
            <div className={`${s.table_body}`}>
              <div className={`${s.table_body_container}`}>
                {
                  ordersArray.map((entity, index) => (
                    <ul key={index}>
                      <li>18-06-2022 10:38:42</li>
                      <li className="dusted-purple-text">{Math.floor((Math.random() * 90) + 100) }</li>
                      <li className="dusted-purple-text">{Math.floor((Math.random() * 900) + 1000) }</li>
                      <li className="blue-text">9a24688899...099076e723</li>
                      <li className="blue-text">ETH</li>
                      <li className="blue-text">9a24688899...099076e723</li>
                      <li>
                        <Tooltip
                          title={<span className="f-15">Withdrawal is going to expire in ~28 minutes</span>}
                          arrow
                          placement="top"
                        >
                          <span className="bg-light-red centralize red-text p-5 p-l-10 p-r-10 br-10 m-r-5">Failed</span>
                        </Tooltip>
                      </li>
                    </ul>
                  ))
                }
              </div>
            </div>
          </div>

          <footer className={`${s.paper_footer} flex flex-row justify-space-between align-center p-10 p-l-20 p-r-30`}>
            <span className="grey-text">Showing 1 to 20 of 97 results</span>
            <ButtonGroup>
              <Button><ChevronLeftIcon /></Button>
              <Button>1</Button>
              <Button>2</Button>
              <Button>...</Button>
              <Button>9</Button>
              <Button>10</Button>
              <Button><ChevronRightIcon /></Button>
            </ButtonGroup>
          </footer>
        </Paper>
      </section>
    </main>
  );
}