import React, { useState } from "react";
import { Dialog, Select, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpIcon from "@mui/icons-material/Help";
import WarningIcon from "@mui/icons-material/Warning";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./ConnectWalletModal.module.scss";
import { generalImages } from "../../constants";

type TProps = {
  modalOpen: boolean,
  setModalOpen :React.Dispatch<React.SetStateAction<boolean>>
}

export default function ConnectWalletModal({ modalOpen, setModalOpen }:TProps):JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [ dropdownState, setDropdownState ] = useState<boolean>(false);
  const [ selectedItem, setSelectedItem ] = useState({ image: "", abbreviation: "" });
  const [ errorFlag, setErrorFlag ] = useState<boolean>(false);

  const handleModalClose = ():void => {
    setModalOpen(false);
  };

  const handleDropdown = () => {
    setDropdownState(!dropdownState);
  };

  const handleSelectItem = (image, abbreviation) => {
    setSelectedItem({
      image, abbreviation
    });
    setDropdownState(!dropdownState);
  };

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorFlag(true);
  };

  return (
    <Dialog
      open={modalOpen}
        // style={{maxWidth: "1500px", backgroundColor: "red"}}
      className={s.dialog_container}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      {/*= ========================= */}
      {/* Button For Closing Modal */}
      {/*= ========================= */}
      <button type="button" onClick={handleModalClose} className={s.close_button}>
        <CloseIcon />
      </button>

      <form
        className={`p-20 ${s.new_institution_modal}`}
        onSubmit={handleSubmit}
        style={{}}
      >
        <h2 className="m-t-0 m-b-25 b-500">Connect to wallet</h2>

        <div className={`${s.metamask} p-l-10 flex flex-row align-center justify-space-between bg-light-silver border-silver br-10 `}>
          <span className="flex flex-row g-10 align-center">
            <h3 className="">Metamask</h3>
            <span className="grey-text flex flex-row align-center">
              Networks
              <Tooltip
                title={(
                  <div>
                    <h1 className="white-text">Supported Networks</h1>
                    <ul className="">
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">1</span> Ethereum Mainnet</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5  p-l-10 p-r-10 br-5">3</span> Ethereum Testnet Rinkeby</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">4</span> Ethereum Testnet Rinkeby</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">5</span> Ethereum Testnet Goerli</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">42</span> Ethereum Testnet Kovan</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">56</span> Binance Smart Chain Mainnet</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">97</span> Binance Smart Chain Testnet</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">139</span> Matic(Polygon) Mainnet</li><br />
                      <li className="f-15 white-text"><span className="white-text bg-grey p-5 p-l-10 p-r-10 br-5">80001</span> Matic Testnet Mumbai</li><br />
                    </ul>
                  </div>
                )}
                arrow
                placement="bottom"
              >
                <HelpIcon className={`${s.help_icon}`} />
              </Tooltip>
            </span>
          </span>
          <div className={`${s.metamask_img_container}`}>
            <img src={generalImages.metamask} alt="" className="" />
          </div>
        </div>

        <div className={`${s.metamask} m-t-20 p-l-10 p-r-10  flex flex-row align-center justify-space-between bg-light-silver border-silver br-10`}>
          <h3 className="">WalletConnect</h3>
          <div className={`${s.metamask_img_container}`}>
            <img src={generalImages.walletConnect} alt="" className="" />
          </div>
        </div>

        <div className={`${s.notice} m-t-25 grey-text`}>
          <p className="no-m-p f-18">
            By connecting a wallet, you agree to Opendax
            <a href="/" className="blue-text no-underline"> Terms of service</a>
          </p>
        </div>
      </form>
    </Dialog>
  );
}