import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, ButtonGroup, Paper } from "@mui/material";
import s from "./All.module.scss";

export default function AllOrders():JSX.Element {
  const ordersArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21 ];

  return (
    <main className={`${s.all_Orders_main}`}>
      <section className={`${s.open_orders}`}>
        <Paper elevation={0} className={`fullwidth fullheight ${s.orders_card}`}>
          {/* <div className={`p-10 p-l-15 ${s.top} fullwidth`}>
            <h3 className="no-m-p b-500">OPEN ORDERS</h3>
          </div> */}
          <div className={`${s.table} fullwidth`}>
            <div className={`bg-light-silver ${s.table_heading}`}>
              <ul>
                <li>DATE</li>
                <li>MARKET</li>
                <li>SIDE</li>
                <li>PRICE</li>
                <li>AMOUNT</li>
                <li>VALUE</li>
                <li>FILLED</li>
                <li>STATUS</li>
                <li className="crimson-text">CANCEL ALL</li>
              </ul>
            </div>
            <div className={`${s.table_body}`}>
              <div className={`${s.table_body_container}`}>
                {
                  ordersArray.map((entity, index) => (
                    <ul key={index}>
                      <li>18-06-2017 12:38:42</li>
                      <li className="dusted-purple-text">ETH/USDT</li>
                      <li className="green-text">Buy</li>
                      <li className="dusted-purple-text">3582.18</li>
                      <li className="dusted-purple-text">4030.40</li>
                      <li className="dusted-purple-text">4030.40</li>
                      <li className="dusted-purple-text">0%</li>
                      <li className="bg-light-green dark-green-text centralize p-5 br-10 m-r-5">Pending</li>
                      <li className="red-text b-500 flex flex-row align-center">Cancel <CloseIcon /></li>
                    </ul>
                  ))
                }
              </div>
            </div>
          </div>

          <footer className={`${s.paper_footer} flex flex-row justify-space-between align-center p-10 p-l-20 p-r-30`}>
            <span className="dusted-purple-text">Showing 1 to 10 of 97 results</span>
            <ButtonGroup>
              <Button><ChevronLeftIcon /></Button>
              <Button>1</Button>
              <Button>2</Button>
              <Button>...</Button>
              <Button>9</Button>
              <Button>10</Button>
              <Button><ChevronRightIcon /></Button>
            </ButtonGroup>
          </footer>
        </Paper>
      </section>
    </main>
  );
}