import userImg from "../../assets/nav/user.png";
import circle_and_arrow from "../../assets/nav/circle-and-arrow.svg";
import collapse from "../../assets/nav/collapse.svg";
import expand from "../../assets/nav/expand.svg";
import exit from "../../assets/nav/exit.svg";
import history from "../../assets/nav/history.svg";
import line_and_arrow from "../../assets/nav/line-and-arrow.svg";
import line_and_circle from "../../assets/nav/line-and-circle.svg";
import logo from "../../assets/nav/logo.svg";
import logo_and_text from "../../assets/nav/logo-and-text.svg";
import metamask from "../../assets/nav/metamask.svg";
import settings from "../../assets/nav/settings.svg";
import short_menu_item from "../../assets/nav/short-menu-item.svg";
import trade from "../../assets/nav/trade.svg";
import wallet from "../../assets/nav/wallet.svg";

export default {
  userImg,
  circle_and_arrow,
  collapse,
  expand,
  exit,
  history,
  line_and_arrow,
  line_and_circle,
  logo,
  logo_and_text,
  metamask,
  settings,
  short_menu_item,
  trade,
  wallet
};