import btc from "../../assets/trade/images/btc.svg";
import cardano from "../../assets/trade/images/cardano.svg";
import doge from "../../assets/trade/images/doge.svg";
import eth from "../../assets/trade/images/eth.svg";
import link from "../../assets/trade/images/link.svg";
import tron from "../../assets/trade/images/tron.svg";
import usdt from "../../assets/trade/images/usdt.svg";
import deposit from "../../assets/trade/images/deposit.svg";
import withdraw from "../../assets/trade/images/withdraw.svg";

export default {
  btc,
  cardano,
  doge,
  eth,
  link,
  tron,
  usdt,
  deposit,
  withdraw
};