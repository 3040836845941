import { Paper } from "@mui/material";
import s from "./Balances.module.scss";
import { tokenImages, tokens } from "../../../constants";

export default function Balances():JSX.Element {
  const tokenArr = [ 1, 2 ];
  return (
    <Paper elevation={2} className={`${s.balance_main} fullwidth fullheight`}>
      <div className={`${s.title_container} p-10 p-l-15`}>
        <h3 className="no-m-p b-600">BALANCES</h3>
      </div>
      <div className={`${s.list_table_container}`}>
        <div className={`${s.list_table}`}>
          {
            tokenArr.map(() => (
              tokens.map((entity, index) => (
                <ul key={index} className="">
                  <li className="">
                    <img src={entity.image} alt="" />
                  </li>
                  <li className="flex flex-column">
                    <span className="">{entity.name}</span>
                    <span className="dusted-purple-text">{entity.abbreviation}</span>
                  </li>
                  <li className="flex flex-column">
                    <span className="">{entity.value}</span>
                    <span className="dusted-purple-text">≈ ${entity.dollarValue}</span>
                  </li>
                  <li className={`${s.deposit}`}>
                    <img src={tokenImages.deposit} alt="deposit" />
                  </li>
                  <li className={`${s.withdraw}`}>
                    <img src={tokenImages.withdraw} alt="withdraw" />
                  </li>
                </ul>
              ))
            ))
          }
        </div>
      </div>
    </Paper>
  );
}