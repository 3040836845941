// import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DepositModal from "../Deposit_Modal/DepositModal";
import WithdrawModal from "../Withdraw_Modal/WithdrawModal";
import ConnectWalletModal from "../Connect_Wallet_Modal/ConnectWalletModal";
import { nav, tokens, navEntities } from "../../constants";
import s from "./Sidenav.module.scss";

// const routesWithoutNav = ["/login", "/feedback", "/get-help", "/forgot-password"];

/*= ============== */
/* Function Body */
/*= ============== */
export default function Sidenav() {
  const url = useLocation().search;
  const query = new URLSearchParams(url).get("tab");
  const tokenRepetition = [ 1, 2, 3, 4 ];
  const [ navCollapsed, setNavCollapsed ] = useState<boolean>(false);
  const [ depositModalOpen, seDeposittModalOpen ] = useState<boolean>(false);
  const [ withdrawModal, setWithdrawModal ] = useState<boolean>(false);
  const [ marketSelectorOpen, setMarketSelectorOpen ] = useState<boolean>(false);
  const [ subMenuOpen, setSubMenuOpen ] = useState<boolean>(false);
  const [ connectWalletModal, setConnectWalletModal ] = useState<boolean>(false);
  const [ logoAndText, setLogoAndText ] = useState<boolean>(false);
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  // const SideNavRef = useRef < any >({ className: s.sidenav });
  // const navbarRef = useRef < any >({ className: s.navbar });
  // const mainRef = useRef < any >({ className: s.nav_main });
  // const switchRef = useRef(false);

  // const paths = pathname.split("/").map((path) => path.charAt(0).toUpperCase() + path.replace("-", " ").slice(1));

  // const [navPath, setNavPath] = useState < string > ("");
  // const [switchState, setSwitchState] = useState(false);

  // useEffect(()=>{
  //   if(pathname === "/"){
  //     navigate("/login")
  //   }
  // },[])

  // useEffect(() => {
  //   console.log(`${localStorage.getItem("navState")} local guy`);

  //   if (localStorage.getItem("navState") === null) {
  //     mainRef.current.className = s.nav_main;
  //     SideNavRef.current.className = s.sidenav;
  //     navbarRef.current.className = s.navbar;
  //     localStorage.setItem("navState", "true");
  //   } else if (localStorage.getItem("navState") === "true") {
  //     mainRef.current.className = s.main_nav_toggle;
  //     SideNavRef.current.className = s.sidenav_toggle;
  //     navbarRef.current.className = s.navbar_toggle;
  //     localStorage.removeItem("navState");
  //   }
  // }, [switchState]);

  /*= =============================================== */
  /* Logic for displaying the nav on selected pages */
  /*= =============================================== */
  // if(routesWithoutNav.some(route => pathname.includes(route))){
  //   console.log("children exist here")
  //   return <main className={s.children_main}>{children && children}</main>;
  // }

  return (
    <main style={{ width: `${navCollapsed === true ? "max-content" : "max-content"}` }} className={`${s.nav_main}`}>

      {/* =============== */}
      {/* SideNav Section */}
      {/* =============== */}
      <nav className={`${s.sidenav} p-8 p-t-20 p-b-20 flex `}>
        <div className={`${s.nav_container} flex flex-column justify-space-between fullheight `}>
          <section className={`${s.top_section} flex flex-column `}>
            { !navCollapsed && <img src={nav.userImage.logo} alt="" className="m-b-40" /> }
            { navCollapsed && <img src={nav.userImage.logo_and_text} alt="opendax logo" className={`m-b-40 p-l-10 ${s.logo_and_text}`} /> }
            <ul className="flex flex-column  g-5">
              {
                navEntities.map((entity, index) => (
                  // eslint-disable-next-line
                  <li onClick={() => setSubMenuOpen(!subMenuOpen)} key={index} className="flex flex-column align-cente">
                    <div className={`${s.main_item} flex flex-row g-10 fullwidth`}>
                      <details className="fullwidth">
                        <summary className="flex flex-row align-center justify-cente g-10 br-10">
                          <Link to={entity.link}>
                            <button type="button" className="p-20">
                              <img src={entity.image} alt={entity.name} className="" />
                            </button>
                          </Link>
                          { navCollapsed && <strong>{entity.name}</strong>}
                        </summary>
                        { navCollapsed
                          && (
                            entity?.subMenu?.map((subItem, subIndex) => (
                              <Link to={subItem.link} className={`${s.subItem_link}`}>
                                <div key={subIndex} className="flex flex-column m-t-0 m-l-75">
                                  <p className={`${query === subItem.link.split("=")[1] ? s.subItem_active : ""} b-600 m-b-15 m-b-0`}>{subItem.name}</p>
                                </div>
                              </Link>
                            ))
                          )}
                      </details>
                    </div>
                  </li>
                ))
              }
            </ul>
          </section>

          <section className={`${s.bottom_section}`}>
            <ul className="flex flex-column g-5">
              <li className="">
                <a href="https://hycient.vercel.app" rel="noopener noreferrer" target="_blank" className="p-15">
                  <BusinessCenterIcon />
                </a>
              </li>
              {
                !navCollapsed && (
                  <>
                    {/* eslint-disable-next-line */}
                    <li onClick={() => setWithdrawModal(true)} className="">
                      <button type="button" className="">
                        <img src={nav.userImage.circle_and_arrow} alt="" className="" />
                      </button>
                    </li>
                    {/* eslint-disable-next-line */}
                    <li onClick={() => seDeposittModalOpen(true)} className="">
                      <button type="button" className="">
                        <img src={nav.userImage.line_and_arrow} alt="" className="" />
                      </button>
                    </li>
                  </>
                )
              }
              <li className="">
                <button onClick={() => setConnectWalletModal(true)} type="button" className="">
                  <img src={nav.userImage.metamask} alt="" className="" />
                </button>
                {
                  navCollapsed
                  && (
                    <span className="flex flex-column">
                      <strong>0.000244 ETH</strong>
                      <span className="dusted-purple-text">0xGD63...6DS9</span>
                    </span>
                  )
                }
              </li>
              {/* eslint-disable-next-line */}
              <li onClick={() => { setMarketSelectorOpen(!marketSelectorOpen); setNavCollapsed(false) }} className="flex flex-row justify-center max-content p-l-10 p-r-10">
                <button type="button" className="">
                  <img src={nav.userImage.exit} alt="" className="" />
                </button>
              </li>

              {
                navCollapsed && (
                  <li className={`${s.button_container} flex flex-row g-5`}>
                    <button onClick={() => seDeposittModalOpen(true)} type="button" className="btn border-silver bg-white">
                      Deposit
                    </button>
                    <button onClick={() => setWithdrawModal(true)} type="button" className={`${s.withdraw_desktop} btn white-text`}>
                      Withdraw
                    </button>
                  </li>
                )
              }
              {/* eslint-disable-next-line */}
              <li onClick={() => setNavCollapsed(!navCollapsed)} className="">
                <button type="button" className="">
                  <img src={nav.userImage.expand} alt="" className="" />
                </button>
                { navCollapsed && <strong>Collapse</strong>}
              </li>
            </ul>
          </section>
        </div>
      </nav>

      {/* ======================= */}
      {/* Market Selector Section */}
      {/* ======================= */}
      {
        marketSelectorOpen
        && (
          <section className={`${s.market_selector}`}>
            <div className={`${s.search_bar_container} p-10`}>
              <input type="text" className="p-10 border-silver br-5 fullwidth f-15" placeholder="All" />
              <div className="flex flex-row justify-space-between m-t-15">
                <span className="dusted-purple-text f-15">Asset</span>
                <span className="dusted-purple-text f-15">Last price</span>
              </div>
            </div>
            <div className={`${s.coin_list_container}`}>
              <ul className={`${s.coin_list}`}>
                {
                  tokenRepetition.map(() => (
                    tokens.map((token, index) => (
                      <li className={`${s.coin} flex flex row align-center g-10 p-10 fullwidth`}>
                        <div className={`${s.coin_icon_container}`}>
                          <img src={token.image} alt={token.name} className="" />
                        </div>
                        <div className={`${s.coin_details} fullwidth flex flex-column g-5`}>
                          <div className="flex flex-row justify-space-between">
                            <p className="no-m-p"><strong>{token.abbreviation} / <span className="dusted-purple-text">USDT</span></strong></p>
                            <strong className="">49,220.60</strong>
                          </div>
                          <div className="flex flex-row justify-space-between">
                            <p className="no-m-p dusted-purple-text">Vol 2.8b</p>
                            <p className="no-m-p green-text">+3,73%</p>
                          </div>
                        </div>
                      </li>
                    ))
                  ))
                }
              </ul>
            </div>
            {/* eslint-disable-next-line */}
            <div onClick={() => setMarketSelectorOpen(false)} className={s.blur_cover} />
          </section>
        )
      }

      <DepositModal
        modalOpen={depositModalOpen}
        setModalOpen={seDeposittModalOpen}
      />

      <WithdrawModal
        modalOpen={withdrawModal}
        setModalOpen={setWithdrawModal}
      />

      <ConnectWalletModal
        modalOpen={connectWalletModal}
        setModalOpen={setConnectWalletModal}
      />

      {/* <div className={s.inscription}>
        <h2>Unable to preview on mobile device</h2>
        <br />
        <h2>Switch to PC</h2>
      </div> */}
      <section className={`${s.my_portfilio_link} maxwidth flex flex-row justify-center`}>
        <a href="https://hycient.vercel.app/" rel="noreferrer noopener" target="_blank" className="p-5 centralize no-underline blue-text b-600">hycient.vercel.app</a>
      </section>
    </main>
  );
}