import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import s from "./OrderBook.module.scss";

type TProps = {
  chartInView: boolean,
  setChartInView :React.Dispatch<React.SetStateAction<boolean>>
}

export default function OrderBook({ chartInView, setChartInView }: TProps):JSX.Element {
  const arr = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ];
  const [ reload, setReload ] = useState<number>(1);

  const triggerAnimation = () => {
    setReload(Math.random());
  };

  return (
    // eslint-disable-next-line
    <Paper onClick={triggerAnimation} elevation={2} className={`${s.order_book} ${chartInView && s.hide} fullwidth fullheight`}>
      <div className={`p-10 p-l-15 ${s.title_container}`}>
        <h3 className="no-m-p b-600">ORDER BOOK</h3>
      </div>
      <div className={`${s.body_container}`}>
        <div className={`${s.body_heading} p-10 bg-light-silver flex flex-row justify-space-between`}>
          <span className="">Price</span>
          <span className="">Amount</span>
          <span className="">Total</span>
          <span className={`${s.price_mobile}`}>Price</span> {/* Hidden on desktop */}
        </div>

        <div className={`${s.body_content}`}>
          <div className={`${s.losers}`} onScroll={triggerAnimation}>
            <div className={`${s}`}>
              {
                arr.map((entity, index) => (
                  <ul data-att="30%" key={index}>
                    <li>63254.65</li>
                    <li className="dusted-purple-text">0.04459</li>
                    <li className="dusted-purple-text">2,820.524</li>
                    <li style={{ width: `${(Math.random() * 100)}%` }} className={`${s.highlight}`} />
                  </ul>
                ))
            }
            </div>
          </div>
          <h3 className={`${s.divider} flex align-center justify-center green-text f-20 b-600 centralize`}>
            62,238.00 USDT
          </h3>
          <div className={`${s.gainers}`} onScroll={triggerAnimation}>
            <div className={`${s}`}>
              {
              arr.map((entity, index) => (
                <ul key={index}>
                  <li>63254.65</li>
                  <li className="dusted-purple-text">0.04459</li>
                  <li className="dusted-purple-text">2,820.524</li>
                  <li style={{ width: `${(Math.random() * 100)}%` }} className={`${s.highlight}`} />
                </ul>
              ))
            }
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}