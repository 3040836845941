import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import s from "./App.module.scss";
import Sidenav from "./components/Sidenav/Sidenav";
import Orders from "./pages/Orders/Orders";
import Trade from "./pages/Trade/Trade";
import History from "./pages/History/History";
import Settings from "./pages/Settings/Settings";
import Balances from "./pages/Balances/Balances";

function App() {
  const theme = localStorage.getItem("theme");
  if (theme === null) {
    localStorage.setItem("theme", "true");
  }
  const [ themeState, setThemeState ] = useState<string | null>(theme);

  useEffect(() => {
    setThemeState(theme);
    if (themeState === "true") {
      document.documentElement.className = "dark-mode";
    } else if (themeState === "false") {
      document.documentElement.className = "";
    }
  }, [ themeState ]);

  return (
    <BrowserRouter>
      <body className={`${s.app_body}`}>
        <Sidenav />
        <Routes>
          <Route path="/" element={<Trade />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/history" element={<History />} />
          <Route path="/balances" element={<Balances />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </body>
    </BrowserRouter>
  );
}

export default App;