import { useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, ButtonGroup, MenuItem, Paper, Select } from "@mui/material";

import s from "./CreateOrder.module.scss";

type TProps = {
  chartInView: boolean,
  setChartInView :React.Dispatch<React.SetStateAction<boolean>>
}
export default function CreateOrder({ chartInView, setChartInView }: TProps):JSX.Element {
  const [ transaction, setTransaction ] = useState({
    action: "", limit: "", price: 0, amount: 0, percentage: 0
  });

  return (
    <Paper elevation={2} className={`${s.order_paper} fullwidth fullheight`}>
      <div className={`p-10 p-l-15 ${s.title_container}`}>
        <h3 className="no-m-p b-600">CREATE ORDER</h3>
      </div>

      <div className={`${s.mobile_view_switch}`}>
        <button onClick={() => setChartInView(false)} type="button" className={`${chartInView === false && s.active_view_button}`}>Orderbook</button>
        <button onClick={() => setChartInView(true)} type="button" className={`${chartInView === true && s.active_view_button}`}>Chart</button>
      </div>

      <div className={`p-15 ${s.body_container}`}>
        <div className={`${s.top_container} flex g-10`}>
          <ButtonGroup className={`fullwidth flex flex-row ${s.top_buttons}`}>
            <Button>Buy</Button>
            <Button>Sell</Button>
          </ButtonGroup>

          <Select className={`${s.limit_selection} fullwidth`} label="Limit" variant="outlined">
            <MenuItem>Limit</MenuItem>
          </Select>
        </div>

        <div className={`${s.price_input} fullwidth flex flex-row justify-space-between border-silver br-5 p-7`}>
          {/* eslint-disable-next-line */}
          <button type="button" onClick={() => setTransaction({ ...transaction, price: transaction.price > 0 ? transaction.price - 1 : 0 })} className="bg-transparent br-5 p-r-10"><RemoveOutlinedIcon /></button>
          <input
            type="number"
            min={0}
            value={transaction.price === 0 ? "he" : transaction.price}
            onChange={(e) => setTransaction({ ...transaction, price: Number(e.target.value) })}
            className="centralize f-15 no-m-p"
            placeholder="Price (USDT)"
          />
          {/* eslint-disable-next-line */}
          <button type="button"  onClick={() => setTransaction({ ...transaction, price: transaction.price + 1})} className="bg-transparent p-l-10 br-5"><AddOutlinedIcon /></button>
        </div>

        <div className={`${s.indicator} fullwidth flex flex-row justify-space-between`}>
          <span className="flex g-5 green-text align-center">
            <ArrowDropUpIcon />
            <p className="no-m-p">59,780.04</p>
          </span>
          <span className="flex g-5 crimson-text align-center">
            <ArrowDropDownIcon />
            <p className="no-m-p">59,780.06</p>
          </span>
        </div>

        <div className={`${s.price_input} fullwidth flex flex-row justify-space-between border-silver br-5 p-7`}>
          {/* eslint-disable-next-line */}
          <button type="button" onClick={() => setTransaction({ ...transaction, amount: transaction.amount > 0 ? transaction.amount - 1 : 0 })} className="bg-transparent p-r-10"><RemoveOutlinedIcon /></button>
          <input type="number" value={transaction.amount === 0 ? "he" : transaction.amount} onChange={(e) => setTransaction({ ...transaction, amount: Number(e.target.value) })} className="centralize f-15 no-m-p" placeholder="Amount (BTC)" />
          {/* eslint-disable-next-line */}
          <button type="button" onClick={() => setTransaction({ ...transaction, amount: transaction.amount + 1})} className="bg-transparent p-l-10"><AddOutlinedIcon /></button>
        </div>

        <div className={`${s.percentages}`}>
          <button type="button" className="p--l-10 dusted-purple-text p-t-10 p-r-10 p-b-10 br-5">25%</button>
          <button type="button" className="p--l-10 dusted-purple-text p-t-10 p-r-10 p-b-10 br-5">50%</button>
          <button type="button" className="p--l-10 dusted-purple-text p-t-10 p-r-10 p-b-10 br-5">75%</button>
          <button type="button" className="p--l-10 dusted-purple-text p-t-10 p-r-10 p-b-10 br-5">100%</button>
        </div>

        <div className="fullwidth flex flex-row justify-space-between">
          <p className="no-m-p flex flex-column">
            <span className="b-600 dusted-purple-text">Total</span>
            <span className="f-12 dusted-purple-text">Avaliable</span>
          </p>
          <p className="no-m-p flex flex-column right-align">
            <span className="">0.00 <span className="dusted-purple-text">USDT</span></span>
            <span className="f-12">1.5031 <span className="dusted-purple-text">USDT</span></span>
          </p>
        </div>

        <button type="button" className="btn fullwidth bg-green white-text">Buy</button>
      </div>
    </Paper>
  );
}