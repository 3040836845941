import navImages from "./images";

type TNavEntities = {
  name: string,
  image: string,
  link: string,
  subMenu?: {
    name?: string
    link: string
  }[]
}[];

const navEntities: TNavEntities = [
  {
    name: "Trade",
    image: navImages.trade,
    link: "/"
  },
  {
    name: "Orders",
    image: navImages.line_and_circle,
    link: "/orders",
    subMenu: [
      {
        name: "All",
        link: "/orders?tab=all"
      },
      {
        name: "Open",
        link: "/orders?tab=open"
      }
    ]
  },
  {
    name: "History",
    image: navImages.history,
    link: "/history",
    subMenu: [
      {
        name: "Deposit",
        link: "/history?tab=deposit"
      },
      {
        name: "Withdraw",
        link: "/history?tab=withdraw"
      }
    ]
  },
  {
    name: "Wallet",
    image: navImages.wallet,
    link: "/balances"
  },
  {
    name: "Settings",
    image: navImages.settings,
    link: "/settings"
  }
];

export default navEntities;