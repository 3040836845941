import View from "../../components/View/View";
import AllOrders from "./All/All";
import OpenOrders from "./Open/Open";
import s from "./Orders.module.scss";

export default function Orders():JSX.Element {
  return (
    <main className={`${s.orders_main} p-15`}>
      <View
        props={[
          {
            viewText: "All",
            component: <AllOrders />
          },
          {
            viewText: "Open",
            component: <OpenOrders />
          }
        ]}
      />
    </main>
  );
}