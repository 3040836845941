import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import s from "./View.module.scss";

type TView = {
  props: {
    viewText: string,
    component: JSX.Element
  }[];
};

export default function View({ props }: TView) {
  const [ view, setView ] = useState<string>(props[0].viewText);

  const url = useLocation().search;
  const query = new URLSearchParams(url).get("tab");

  useEffect(() => {
    if (query) {
      setView(query as string);
    }
  }, [ query ]);

  return (
    <main className={s.view_main}>
      <nav className={`${s.section_navigation}`}>
        <ul className={`flex flex-row ${s.navigation_items}`}>
          {
          props.map((prop, index) => (
            // eslint-disable-next-line
            <li
              key={index}
              className={view.toLowerCase() === prop.viewText.toLowerCase() ? s.view_nav_active : ""}
              onClick={() => setView(prop.viewText)}
            >{prop.viewText}
            </li>
          ))
          }
        </ul>
      </nav>
      {
        props.map((prop) => (
          view.toLowerCase() === prop.viewText.toLowerCase() && prop.component
        ))
      }
    </main>
  );
}