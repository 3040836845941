import tokenImage from "./images";

const tokens = [
  {
    name: "Bitcoin",
    abbreviation: "BTC",
    image: tokenImage.btc,
    value: 2.66850000,
    dollarValue: 131000.01
  },
  {
    name: "Ethereum",
    abbreviation: "ETH",
    image: tokenImage.eth,
    value: 2.66850000,
    dollarValue: 5493.01
  },
  {
    name: "Cardano",
    abbreviation: "ADA",
    image: tokenImage.cardano,
    value: 2.66,
    dollarValue: 6.94
  },
  {
    name: "Cardano",
    abbreviation: "TRON",
    image: tokenImage.tron,
    value: 2.66,
    dollarValue: 6.94
  },
  {
    name: "Chainlink",
    abbreviation: "LINK",
    image: tokenImage.link,
    value: 2.66850000,
    dollarValue: 54.02
  },
  {
    name: "Tether",
    abbreviation: "USDT",
    image: tokenImage.usdt,
    value: 2.66,
    dollarValue: 2.66
  },
  {
    name: "Tether",
    abbreviation: "USDT",
    image: tokenImage.usdt,
    value: 2.66,
    dollarValue: 2.66
  },
  {
    name: "Dogecoin",
    abbreviation: "DOGE",
    image: tokenImage.doge,
    value: 2.66,
    dollarValue: 0.66
  }
];

export default tokens;