import View from "../../components/View/View";
import Deposit from "./Deposit/Deposit";
import Withdraw from "./Withdraw/Withdraw";
import s from "./History.module.scss";

export default function History():JSX.Element {
  return (
    <main className={`${s.history_main} p-15`}>
      <View
        props={[
          {
            viewText: "Deposit",
            component: <Deposit />
          },
          {
            viewText: "Withdraw",
            component: <Withdraw />
          }
        ]}
      />
    </main>
  );
}