import { useState } from "react";
import Balances from "./Balances/Balances";
import CreateOrder from "./Create_Order/CreateOrder";
import OrderBook from "./Order_Book/OrderBook";
import s from "./Trade.module.scss";
import TradeMain from "./Trade_Main/TradeMain";

export default function Trade() {
  const [ chartInView, setChartInView ] = useState<boolean>(false);

  return (
    <div className={s.trade_main}>
      <div className={`${s.trade_container}`}>
        <main className={`${s.page_main}`}>
          <TradeMain
            chartInView={chartInView}
            setChartInView={setChartInView}
          />
        </main>
        <div className={`${s.order_book}`}>
          <OrderBook
            chartInView={chartInView}
            setChartInView={setChartInView}
          />
        </div>
        <div className={`${s.create_order}`}>
          <div className={`${s.top}`}>
            <CreateOrder
              chartInView={chartInView}
              setChartInView={setChartInView}
            />
          </div>
          <div className={`${s.balances}`}>
            <Balances />
          </div>
        </div>
      </div>
    </div>
  );
}